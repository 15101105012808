var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "background-size": "3000px 3000px" },
      attrs: { id: "mainlogin" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLogin,
              expression: "isLogin",
            },
          ],
          attrs: { id: "back" },
        },
        [
          _c("div", { staticClass: "areaformulario" }, [
            _c("div", { attrs: { id: "login" } }, [
              _c("div", { staticClass: "form" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  staticClass: "form-input",
                  attrs: {
                    type: "email",
                    id: "email",
                    required: "",
                    placeholder: "Email",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                  ],
                  staticClass: "form-input",
                  attrs: {
                    type: "password",
                    id: "password",
                    placeholder: "Contraseña",
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    },
                  },
                }),
                _vm.error
                  ? _c("p", { staticClass: "error" }, [
                      _vm._v("Email o contraseña incorrectos."),
                    ])
                  : _vm._e(),
                _c("input", {
                  staticClass: "form-submit",
                  attrs: { value: "Iniciar Sesión" },
                  on: { click: _vm.login },
                }),
                _c("p", [
                  _c(
                    "a",
                    {
                      staticStyle: { float: "right" },
                      on: { click: _vm.registrarse },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "colorElemento1 registroLaunch" },
                        [_vm._v("Crear una cuenta")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLogin,
              expression: "!isLogin",
            },
          ],
          attrs: { id: "back2" },
        },
        [
          _c("div", { staticClass: "areaformulario" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLogin,
                    expression: "!isLogin",
                  },
                ],
                attrs: { id: "registro" },
              },
              [
                _c("div", { staticClass: "form" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "colorElemento1",
                      staticStyle: {
                        "font-weight": "bolder",
                        color: "#2EA7D2",
                      },
                    },
                    [_vm._v(" Registrar usuario nuevo ")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.datosRegistro.email,
                        expression: "datosRegistro.email",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "email",
                      required: "",
                      placeholder: "Email",
                    },
                    domProps: { value: _vm.datosRegistro.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.datosRegistro,
                          "email",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.datosRegistro.password,
                        expression: "datosRegistro.password",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "password",
                      size: "15",
                      required: "",
                      placeholder: "Contraseña",
                    },
                    domProps: { value: _vm.datosRegistro.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.datosRegistro,
                          "password",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.passwordRepeat,
                        expression: "passwordRepeat",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "password",
                      id: "password-repeat",
                      placeholder: "Repite la contraseña",
                    },
                    domProps: { value: _vm.passwordRepeat },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.passwordRepeat = $event.target.value
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.datosRegistro.firstname,
                        expression: "datosRegistro.firstname",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: { required: "", placeholder: "Nombre/s" },
                    domProps: { value: _vm.datosRegistro.firstname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.datosRegistro,
                          "firstname",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.datosRegistro.lastname,
                        expression: "datosRegistro.lastname",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: { required: "", placeholder: "Apellido/s" },
                    domProps: { value: _vm.datosRegistro.lastname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.datosRegistro,
                          "lastname",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.datosRegistro.nickname,
                        expression: "datosRegistro.nickname",
                      },
                    ],
                    staticClass: "form-input",
                    attrs: { placeholder: "Nombre para mostrar" },
                    domProps: { value: _vm.datosRegistro.nickname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.datosRegistro,
                          "nickname",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.errorregistro
                    ? _c("p", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.register_msg)),
                      ])
                    : _vm._e(),
                  _vm.successregistro
                    ? _c("p", { staticClass: "colorElemento1" }, [
                        _vm._v(" " + _vm._s(_vm.register_msg) + " "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    staticClass: "form-submit",
                    attrs: { value: "Registrar" },
                    on: { click: _vm.register },
                  }),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }